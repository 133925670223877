@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');

/* Typography */
body,
p,
a,
button,
a.button {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}
h1,
h2,
h3{
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
}
h2{
    font-size: 18px;
    line-height: 24px;
}
h3{
    font-size: 18px;
    line-height: 24px;
}
.caption {
    font-size: 11px;
    line-height: 14px;
}
h2,
button,
a.button{
    text-transform: uppercase;
}

/* Color */
body {
    color: #333;
    background: linear-gradient(168.6deg, #D3DBE7 0.63%, #8692A6 99.71%);
}
button,
a.button,
.content-white,
a.button:hover {
    color:#fff;
}
button,
a.button {
    background: #6185BE;
}
button:hover,
a.button:hover{
    background: #3861A0;
}
a{
    color:#6185BE;
}
a:hover{
    color:#3861A0;
}
.siteFooter{
    background: linear-gradient(180deg, rgba(154, 165, 183, 0) 0%, #8692A6 76.78%);
}
::selection{
    background-color: #F2C94C;
}

#cookieConsent{
    background-color: #333;
}

/* Global */
body{
    min-height: 100vh;
}
img{
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
}
a {
    text-decoration: none;
}

/* Logo */
.siteLogo{
    margin:30px auto;
    content: url(./../images/logo-mobile.svg);
}

@media(min-width:768px){
    .siteLogo{
        margin:80px auto;
        content: url(./../images/logo-desktop.svg);
    }
}


/* Button */
button,
a.button {
    display:inline-block;
    text-decoration: none;
    text-align: center;
    border:none;
    border-radius: 5px;
    padding:10px;
    margin-bottom:15px;
}
button.small,
a.button.small {
    padding:3px 7px;
    margin-bottom:5px;
}

/* Footer */
.siteFooter{
    position:fixed;
    width:100%;
    height: 100px;
    bottom:0;
    padding:10px;
}
.siteFooter .container,
.siteFooter .row{
    height: 100%;
}

/* ProjectThumb */
.thumb{
    display:block;
    width: 100%;
    height:200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

/* Floating Noti */
#cookieConsent{
    display: block;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding:5px;
}
#cookieConsent p{
    padding-right: 20px;
    margin:0;
}
#cookieConsent button{
    margin-bottom: 0;
}