.social{
    padding-bottom: 100px;
}
.social i{
    display: inline-block; 
    margin: 10px 15px; 
    font-size: 28px; 
    font-weight:400;
    color:#333;
}
.social i:hover{
    color:#3861A0;
}