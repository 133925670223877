.projects{
    padding:60px 0;
}
.projects h2{
    padding-bottom:16px;
}
.projectsWrap{
    padding-top:16px;
}
.projectsWrap::after {
    content: "";
    flex: auto;
  }