.projectCard{
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
    border: 1px solid #BFC6D1;
    filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.1));
    transition: all 0.3s ease-in-out;
    margin-bottom:40px;
    animation: fadeInAnimation 0.3s ease-in-out;
}
@keyframes fadeInAnimation {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
.projectCard h3{
    margin-top:15px;
}
.projectCard:hover{
    filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.3));
}
/*.projectCard.default{}
.projectCard.active{}
.projectCard.toCollapse{}
.projectCard.collapsed{}*/

.projectCard__desc{
    padding:15px;
    transition: all 0.3s ease-in-out;
}
.projectCard__desc p{
    height: 63px;
    overflow: hidden;
}
.projectCard__button{
    display: block;
    width: 100%;
    margin-top: 15px;
    transition: all 0.1s ease-in-out;
    font-weight: 500;
}
.projectCard__btnCollapse{
    border:none;
    color: #6185BE;
    background-color: #fff;
    width: 100%;
    text-transform: lowercase;
    padding-bottom: 0;
    padding-top:0;
    margin-bottom:0;
}
.projectCard__btnCollapse::before{
    display:block;
    content: "\f106";
}

@media screen and (min-width:768px) {
    .projectCard{
        max-width: 31%;
        margin-right:2%;
    }
    .projectCard__button{
        color: #6185BE;
        background-color: #fff;
        border-color: #fff;
    }
    .projectCard:hover .button{
        background: #6185BE;
        border-color: #6185BE;
        color:#fff;
    }
    .projectCard:hover .button:hover{
        background: #3861A0;
    }
    
}