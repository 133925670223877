/* Tag */
.tagChip{
    display:inline-block;
    border: 1px solid #828282;
    color: #828282;
    background-color: transparent;
    padding:3px 5px;
    border-radius: 10px;
    margin-right:5px;
    margin-bottom:5px;
    font-size: 11px;
    line-height: 14px;
    text-transform: lowercase;
}
.tagChip__active{
    display:inline-block;
    padding:3px 5px;
    border-radius: 10px;
    margin-right:5px;
    margin-bottom:5px;
    font-size: 11px;
    line-height: 14px;
    border: 1px solid #6185BE;
    background-color: #6185BE;
    color: #fff;
    cursor: pointer;
    text-transform: lowercase;
    transition:all 0.3s ease-in-out;
}
.tagChip__active:hover{
    background-color: #3861A0;
    padding:3px 7px;
    font-size: 13px;
    filter: drop-shadow(0px 7px 3px rgba(0, 0, 0, 0.1));
}